<template lang="html">
  <div class="login">
    <div class="container">
      <div v-if="!$store.state.registerMode && !$store.getters.AuthUser" class="main">
        <p class="title">Log in with magic link</p>
        
        <form class="flex fd-col fa-cent space-2" @keyup.enter="log_user(email)">
          <label for="login-email"></label>
          <input type="email" id="login-email" placeholder="email" v-model="email">

          <!-- <label for="login-password" class="space-2"></label> -->
          <!-- <input type="password" class="space-2"
          id="login-password" placeholder="password" v-model="password"> -->

          <div v-if="$store.getters.AuthLoading" class="loading-screen flex fd-col w-100 fj-cent fa-cent">
            <p class="ff-2">loading</p>
            <div class="lds-ring space-2"
            style="--lds-width: calc(var(--size-unit)*3); --lds-height: calc(var(--size-unit)*3);">
              <div></div><div></div><div></div><div></div>
            </div>
          </div>
          <button v-else-if="!$store.getters.AuthLoading" class="space-4" type="button" name="button" @click="log_user(email)">Log in</button>
        </form>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'login',
  data(){
    return {
      email: null,
      pass: null,
      registerMode: false,
    }
  },
  computed: {
    sessionUser(){
      return this.$store.state.authUser
    }
  },
  methods: {
    async log_user(email){
      await this.$store.dispatch('LogInWithMagicLink', (this.email))
    },
  },
  beforeMount(){
    // this.$store.commit('setRegisterModeOff')
  }
}
</script>

<style>
.login {
  margin-bottom: 120px;
}
.login .container{
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 64px);
  margin: 0 auto;
}
.login .main{
  max-width : 320px;
  padding-top: 64px;
}
.login hr{
  margin: 32px 0;
  border:1px dashed var(--over-bg);
}
.login .title {
  margin-bottom: 32px;
  text-align: center;
}
.login .underlined {
  border-bottom: 1px solid var(--over-bg);
  margin-bottom: 24px;
}
.login label{
  margin-left: 0;
}
.register-hook {
  display: inline-block;
  margin: 32px 8px auto 0;
}
</style>
